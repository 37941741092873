<template>
  <div>
    <NavBar :navSelIndex="1" v-if="headFlag"></NavBar>
    <!-- 轮播图 -->
    <b-carousel id="carouselExampleControls" :interval="4000" controls indicators background="#ababab">
      <b-carousel-slide v-for="item in banners" v-bind:key="item.banner_path" :img-src="item.banner_path">
      </b-carousel-slide>
      <div class="search-box">
        <div class="search-input-box">
          <div class="dropdown ">
            <button class="btn btn-secondary dropdown-toggle search-select" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-expanded="false">
              {{ selectInfo.title }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#" v-for="(item, index) in selectInfoList" :key="index"
                @click="selectInfo = item">{{ item.title }}</a>
            </div>
          </div>
          <div class="search-split-box">
            <img src="../../../public/icon/search-split@2x.png" class="search-split" />
          </div>
          <input v-model="keyWords" class="search-input-info" :placeholder="searchPlaceholder" @keyup.enter="search" />

          <div class="search-split-box margin-left-auto">
            <img src="../../../public/icon/product-distingush@2x.png" class="search-icon" @click="openUploadAdd()" />
          </div>
          <div class="search-split-box">
            <img src="../../../public/icon/search-split@2x.png" class="search-split" />
          </div>
          <div class="search-split-box margin-right-22" @click="search()">
            <img src="../../../public/icon/search-icon@2x.png" class="search-icon" />
          </div>
        </div>
        <div :class="openUpload == true ? 'soutu-state-normal' : 'hiddenUpload'">
          <div class="graph-d20-search-layer-choose">

            <div class="general-upload">
              <el-upload class="upload-demo" action="#" :auto-upload="false" ref="upload" :limit="1" :file-list="fileList"
                :on-change="handleFileUpload">
                <i class="el-icon-upload"></i>
                <span>文件上传</span>
              </el-upload>

              <!-- <el-button type="primary" @click="submitupload()">提交</el-button> -->
              <!-- <el-form id="form">
                <input type="file" style="opacity: 0;" onchange="submitImage()">
                <input type="submit" name="submit"/>
             </el-form> -->
            </div>
          </div>

        </div>
        <div class="key-words-box">
          <span class="key-words-title">{{ $t("homeSearchPlaceholder") }}:</span>
          <span class="key-words" v-for="(item, index) in keyWordsList" :key="index" @click="keyWords = item; search()">{{
            item }}</span>
        </div>
      </div>
    </b-carousel>

    <!-- <Slides :list="navLink"></Slides> -->


    <!-- 配置 -->
    <div class="container-index">
      <!-- 视频教程start -->
      <!-- <div class="ehome-title-box">
        <div class="ehome-title">{{ $t("homeVeideoTitle") }}</div>
        <div class="ehome-title-more" @click="toVideoTutorial">{{ $t("homeMoreCourse") }}<i
            class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="video-box">
        <div class="video-box-con" v-for="(item, index) in videoList" :key="index">
          <video controls :src="item.srcUrl" :poster="item.poster" class="video"></video>
        </div>
      </div> -->
      <!-- 视频教程end -->

      <!-- 设计师start -->
      <!-- <div class="ehome-title-box" v-if="designers.length>0">
        <div class="ehome-title">{{$t("homeDesignerTitle")}}</div>
        <div class="ehome-title-more">{{$t("homeChangeTitle")}}<i class="el-icon-refresh"></i></div>
      </div>
      <b-row class="designer-box video-box" v-if="designers.length>0">
        <b-col sm="6" md="4" lg="2" xl="2" v-for="(item,index) in designers" v-bind:key="index" class="designer">
          <div class="designer-item">
            <img class="designer-avatar" :src="item.headImgUrl"
              v-if="item.headImgUrl != '' && item.headImgUrl != null" />
            <img class="designer-avatar" src="img/person.431c96c3.png" v-else />
            <div class="nick-name ehome-subtitle">{{item.nickName}}</div>
            <div class="design-num ehome-design-title">{{item.designNum}}{{$t('homeDesignNumber')}}</div>
            <div class="signature ehome-signature">{{item.mySignature}}</div>
            <button class="follow  ehome-design-title" @click.stop="followDesigner(item)"
              v-if="item.followStatus==1">{{$t('designerFollow')}}</button>
            <button class=" follow ehome-design-title follow-success" @click.stop="followDesigner(item)"
              v-else>{{$t('designerNoFollow')}}</button>
          </div>
        </b-col>
      </b-row> -->
      <!-- 设计师end -->
      <!-- 2d方案start -->
      <div class="ehome-title-box">
        <div class="ehome-title">{{ $t("homeStudySpace") }}</div>
        <div class="label-box">
          <span :class="design2DActiveIdx == index ? 'active label ehome-signature' : 'label ehome-signature'"
            v-for="(item, index) in labels" :key="index" @click="design2DSelectLabel(item, index)">{{ item.labelName
            }}</span>
        </div>
        <div class="ehome-title-more" @click="to2DSolution">{{ $t("homeMoreSolution") }}<i
            class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="solution-box video-box">
        <waterfall :col="colCount" :data="masterpiecesList" class="solution">
          <template>
            <div class="solution-item" v-for="(item, index) in masterpiecesList" :key="index" @click="to2DDetail(item)">
              <div class="solution-item-ehome">
                <img class="solution-img" :src="item.coverUri" />
                <div class="collection-button" @click.stop="followSolution(item)">
                  <i class="el-icon-star-off" v-if="item.collectStatus == 0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.collectStatus == 0">{{ $t('collectionButtonTitle') }}</div>
                  <div class="collection-msg" v-else>{{ $t('cancleButtonTItle') }}</div>
                </div>
                <div class="solution-name ehome-solution-title-name">{{ item.name }}</div>
                <!-- <div class="solution-user-box">
                  <img class="solution-user-avatar" :src="item.headImgUri"
                    v-if="item.headImgUri != '' && item.headImgUri != null" />
                  <img class="solution-user-avatar" src="../../../public/img/person.431c96c3.png" v-else />
                  <div class="solution-designer-name ehome-solution-name"
                    v-if="item.nickName != null && item.nickName != '' && item.nickName != 'null'">
                    {{ item.nickName }}
                  </div>
                  <div class="solution-designer-name ehome-solution-name" v-else>{{ $t('portalTitle') }}
                  </div>
                  <i class="el-icon-view watch-icon"></i>
                  <div class="solution-watch-number ehome-solution-name">
                    {{ item.viewNum == null ? 0 : item.viewNum }}
                  </div>
                </div> -->
              </div>
            </div>
          </template>
        </waterfall>
      </div>
      <!-- 2d方案end -->

      <!-- 场景购画布start -->
      <div class="ehome-title-box">
        <div class="ehome-title">{{ $t("home3DStudySpace") }}</div>
        <div class="label-box">
          <span :class="design3DActiveIdx == index ? 'active label ehome-signature' : 'label ehome-signature'"
            v-for="(item, index) in canvasLabels" :key="index" @click="canvasSelectLabel(item, index)">{{ item.labelName
            }}</span>
        </div>
        <div class="ehome-title-more" @click="dumpCanvasSolution">{{ $t("homeMoreSolution") }}<i
            class="el-icon-arrow-right"></i></div>
      </div>
      <div class="solution-box video-box">

        <!-- <waterfall :col="colCount" :data="designs3dList" class="solution">
          <template>
            <div class="solution-item" v-for="(item,index) in designs3dList" :key="index" @click="to3DDetail(item)">
              <div class="solution-item-ehome">
                <img class="solution-img" :src="item.imgPath" />
                <div class="collection-button" @click.stop="collectionDesign3D(item)">
                  <i class="el-icon-star-off" v-if="item.collectionType==0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.collectionType==0">{{$t('collectionButtonTitle')}}</div>
                  <div class="collection-msg" v-else>{{$t('cancleButtonTItle')}}</div>
                </div>
                <div class="solution-name ehome-solution-title-name">{{item.worksName}}</div>
                <div class="solution-user-box">
                  <div class="solution-designer-name ehome-solution-name">
                    {{item.hourseType}} | {{item.styleName}} | {{item.measureArea}}
                  </div>
                  <i class="el-icon-view watch-icon"></i>
                  <div class="solution-watch-number ehome-solution-name">{{item.viewNum}}</div>
                </div>
              </div>
            </div>
          </template>
        </waterfall> -->
        <div class="solution">
          <b-row class="model-items-wrap b-row">
            <b-col sm="6" md="4" lg="3" xl="2" class="b-col" v-for="(item, index) in canvasList" :key="index">
              <div class="solution-item" @click="toCanvasDetail(item)">
                <div class="solution-item-ehome">
                  <div class="pic">
                    <img class="solution-img" :src="item.coverUri" />
                  </div>
                  <div class="solution-name ehome-solution-title-name">{{ item.title }}</div>
                  <div class="solution-user-box">
                    <!-- <img class="solution-user-avatar" :src="item.designerHeagImgUrl" /> -->
                    <div class="solution-designer-name ehome-solution-name">
                      {{ item.firstTitle }}
                      <!-- {{item.hourseType}} | {{item.styleName}} | {{item.measureArea}} -->
                    </div>
                    <!-- <i class="el-icon-view watch-icon"></i>
                    <div class="solution-watch-number ehome-solution-name">{{item.viewNum}}</div> -->
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- 场景购画布end -->

      <!-- 3d方案start -->
      <!-- <div class="ehome-title-box">
        <div class="ehome-title">{{$t("home3DStudySpace")}}</div>
        <div class="label-box">
          <span :class="design3DActiveIdx == index ? 'active label ehome-signature' : 'label ehome-signature'"
            v-for="(item,index) in design3DLabels" :key="index"
            @click="design3DSelectLabel(item, index)">{{item.labelName}}</span>
        </div>
        <div class="ehome-title-more" @click="dump3DSolution">{{$t("homeMoreSolution")}}<i
            class="el-icon-arrow-right"></i></div>
      </div>
      <div class="solution-box video-box"> -->

      <!-- <waterfall :col="colCount" :data="designs3dList" class="solution">
          <template>
            <div class="solution-item" v-for="(item,index) in designs3dList" :key="index" @click="to3DDetail(item)">
              <div class="solution-item-ehome">
                <img class="solution-img" :src="item.imgPath" />
                <div class="collection-button" @click.stop="collectionDesign3D(item)">
                  <i class="el-icon-star-off" v-if="item.collectionType==0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.collectionType==0">{{$t('collectionButtonTitle')}}</div>
                  <div class="collection-msg" v-else>{{$t('cancleButtonTItle')}}</div>
                </div>
                <div class="solution-name ehome-solution-title-name">{{item.worksName}}</div>
                <div class="solution-user-box">
                  <div class="solution-designer-name ehome-solution-name">
                    {{item.hourseType}} | {{item.styleName}} | {{item.measureArea}}
                  </div>
                  <i class="el-icon-view watch-icon"></i>
                  <div class="solution-watch-number ehome-solution-name">{{item.viewNum}}</div>
                </div>
              </div>
            </div>
          </template>
        </waterfall> -->
      <!-- <div class="solution">
          <b-row class="model-items-wrap b-row">
            <b-col sm="6" md="4" lg="3" xl="2" class="b-col" v-for="(item,index) in designs3dList" :key="index">
              <div class="solution-item" @click="to3DDetail(item)">
                <div class="solution-item-ehome">
                  <div class="pic">
                    <img class="solution-img" :src="item.imgPath" />
                  </div>
                  <div class="collection-button" @click.stop="collectionDesign3D(item)">
                    <i class="el-icon-star-off" v-if="item.collectionType==0"></i>
                    <i class="el-icon-star-on" v-else></i>
                    <div class="collection-msg" v-if="item.collectionType==0">{{$t('collectionButtonTitle')}}</div>
                    <div class="collection-msg" v-else>{{$t('cancleButtonTItle')}}</div>
                  </div>
                  <div class="solution-name ehome-solution-title-name">{{item.worksName}}</div>
                  <div class="solution-user-box">
                     <img class="solution-user-avatar" :src="item.designerHeagImgUrl" />
                    <div class="solution-designer-name ehome-solution-name">
                      {{item.hourseType}} | {{item.styleName}} | {{item.measureArea}}
                    </div>
                    <i class="el-icon-view watch-icon"></i>
                    <div class="solution-watch-number ehome-solution-name">{{item.viewNum}}</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>  -->
      <!-- 3d方案end -->

      <!-- 3d模型 start-->
      <div class="ehome-title-box">
        <div class="ehome-title">{{ $t("home3DModeStudySpace") }}</div>
        <div class="label-box">
          <span :class="model3DActiveIdx == index ? 'active label ehome-signature' : 'label ehome-signature'"
            v-for="(item, index) in model3DClassify" :key="index" @click="model3DSelectLabel(item, index)">{{
              item.classifyName }}</span>
        </div>
        <div class="ehome-title-more" @click="dump3DModeList">{{ $t("homeMoreMode") }}<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="solution-box video-box">
        <!-- <waterfall :col="colCount" :data="modeList" class="solution">
          <template>
            <div class="solution-item" v-for="(item,index) in modeList" :key="index" @click="to3DModelDetail(item)">
              <div class="solution-item-ehome">
                <img class="solution-img mode-img" :src="item.coverUri" />
                <div class="collection-button" @click.stop="collectionMode(item)">
                  <i class="el-icon-star-off" v-if="item.followFlag==0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.followFlag==0">{{$t('collectionButtonTitle')}}</div>
                  <div class="collection-msg" v-else>{{$t('cancleButtonTItle')}}</div>
                </div>
                <div class="solution-name ehome-solution-title-name">{{item.modelName}}</div>
                <div class="solution-user-box">
                  <div class="solution-designer-name-mode ehome-solution-name">ID:{{item.modelId}}
                  </div>
                  <i class="el-icon-view watch-icon"></i>
                  <div class="solution-watch-number ehome-solution-name">{{item.viewTotal}}</div>
                  <i class="el-icon-download icon-padding"></i>
                  <div class="solution-watch-number ehome-solution-name">{{item.downloadTotal}}</div>
                </div>
              </div>
            </div>
          </template>
        </waterfall> -->
        <div class="solution">
          <b-row class="model-items-wrap">
            <b-col sm="6" md="4" lg="3" xl="2" class="solution-item" v-for="(item, index) in modeList" :key="index"
              @click="to3DModelDetail(item)">
              <div class="solution-item-ehome">
                <img class="solution-img mode-img" :src="item.coverUri" />
                <div class="collection-button" @click.stop="collectionMode(item)">
                  <i class="el-icon-star-off" v-if="item.followFlag == 0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.followFlag == 0">{{ $t('collectionButtonTitle') }}</div>
                  <div class="collection-msg" v-else>{{ $t('cancleButtonTItle') }}</div>
                </div>
                <div class="solution-name model-name ehome-solution-title-name" :title="item.modelName">{{ item.modelName
                }}
                </div>
                <div class="solution-user-box">
                  <div class="solution-designer-name-mode ehome-solution-name">ID:{{ item.modelId }}
                  </div>
                  <i class="el-icon-view watch-icon"></i>
                  <div class="solution-watch-number ehome-solution-name">{{ item.viewTotal }}</div>
                  <i class="el-icon-download icon-padding"></i>
                  <div class="solution-watch-number ehome-solution-name">{{ item.downloadTotal }}</div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!--  3d模型 end> -->

    </div>



    <Right></Right>
    <Footer v-if="footerFlag"></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
// import Slides from "@/components/Slides.vue";
import Right from "@/components/Right.vue";
import Footer from "@/components/Footer.vue";
import dump from "../../util/dump.js";
import url from "@/api/config";
// import router from "../../router";

export default {
  components: {
    NavBar,
    // Slides,
    Right,
    Footer
  },
  data() {
    return {
      colCount: 5,
      selectInfo: {
        title: this.$t("homeGoods"),
        id: 1
      },
      selectInfoList: [
        {
          title: this.$t("homeGoods"),
          id: 1
        },
        {
          title: this.$t("homeTwoSolution"),
          id: 2
        },
        {
          title: this.$t("homeThreeSolution"),
          id: 3
        },
        {
          title: this.$t("home3DModel"),
          id: 4
        }
      ],
      design2DActiveIdx: 0,
      design3DActiveIdx: 0,
      model3DActiveIdx: 0,
      keyWordsList: [
        "现代客厅",
        "卧室",
        "极简"
      ],
      banners: [{
        banner_path: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/37700da2-f715-43f7-b39e-f554c0487c90.jpg"
      }
      ],
      keyWords: "",
      searchPlaceholder: this.$t('homeSearchInputPlaceholder'),
      designers: [],
      labels: [{
        labelName: "全部",
        labelId: ""
      },
      {
        labelName: "极简",
        labelId: "1"
      },
      {
        labelName: "轻奢",
        labelId: "2"
      },
      {
        labelName: "中式",
        labelId: "3"
      },
      {
        labelName: "美式",
        labelId: "4"
      },
      {
        labelName: "北欧/现代",
        labelId: "5"
      },
        // {
        //   labelName: "英式",
        //   labelId: "6"
        // },
        // {
        //   labelName: "欧式/新古典",
        //   labelId: "7"
        // }
      ],
      canvasLabels: [{
        labelName: "全部",
        labelId: ""
      },
      {
        labelName: "客厅",
        labelId: "5"
      },
      {
        labelName: "餐厅",
        labelId: "107"
      },
      {
        labelName: "卧室",
        labelId: "106"
      },
      {
        labelName: "书房",
        labelId: "111"
      },
      {
        labelName: "儿童房",
        labelId: "112"
      },
      {
        labelName: "户外",
        labelId: "113"
      },
      {
        labelName: "茶室",
        labelId: "707"
      }
      ],
      design3DLabels: [{
        labelName: "全部",
        labelId: ""
      },
      {
        labelName: "极简",
        labelId: "16"
      },
      {
        labelName: "轻奢",
        labelId: "15"
      },
      {
        labelName: "中式",
        labelId: "53"
      },
      {
        labelName: "美式",
        labelId: "52"
      },
      {
        labelName: "北欧/现代",
        labelId: "54"
      },
      {
        labelName: "英式",
        labelId: "55"
      },
      {
        labelName: "欧式/新古典",
        labelId: "56"
      }
      ],
      model3DClassify: [{
        classifyName: "全部",
        classifyId: ""
      },
      {
        classifyName: "客厅",
        classifyId: "5"
      },
      {
        classifyName: "餐厅",
        classifyId: "107"
      },
      {
        classifyName: "卧室",
        classifyId: "106"
      },
      {
        classifyName: "书房",
        classifyId: "111"
      },
      {
        classifyName: "儿童房",
        classifyId: "112"
      },
      {
        classifyName: "摆件",
        classifyId: "419"
      }
      ],
      solutionList: [{
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/a2a251a4-0041-4e7f-997d-354882b92613.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      {
        coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
        name: "静享“家”的温暖",
        designerName: "许淇栋（形与品设计）",
        watchNumber: 124,
        designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
      },
      ],
      masterpiecesList: [],
      designs3dList: [],
      modeList: [],
      navLink: [],
      videoList: [],
      canvasList: [],
      fileList: [],
      openUpload: 0,
      maxLength: 0,
      searchImage: '',
      headFlag:false,
      footerFlag:false
    }
  },
  created() {
    // console.log(this.$route.params.pathMatch)
    // if (this.$route.params.pathMatch !== undefined){
    //   console.log("pathMatch",this.$route.params.pathMatch)
    //   let base = "/" + this.$route.params.pathMatch
    //   // let base = "/" + this.$route.params.pathMatch.split("/")[1]
    //   console.log("route",base)
    //   const url = "/to-" + this.$route.params.pathMatch
    //   console.log("url",url)
    //   const pattern =  /^[^/]+/
    //   // const pattern = /\/to-[^]*?(?=\s|)\//g;
    //   const pathFlag = url.match(pattern)

    //   console.log("是",pathFlag)
    //   // if(url.)
    //   // let shopInfo = {
    //   //   shopName: this.$route.params.pathMatch
    //   // }
    //   this.$api.getShopInfo({ shopName: this.$route.params.pathMatch }).then(res => {
        
    //     this.cookie.setCookieClose({ netSuffix: base })
    //     this.cookie.setCookieClose({ shopName: res.data.shopName})  
    //     this.cookie.setCookieClose({ headerLogo: res.data.headerLogo})
    //     this.cookie.setCookieClose({ footerLogo: res.data.footerLogo})
    //     this.cookie.setCookieClose({ telephone: res.data.phone})
    //     this.headFlag = true
    //     this.footerFlag = true
    //     // this.cookie.setCookieClose({ footerLogo: res.data.footerLogo})
    //     // this.cookie.setCookieClose({ telephone: res.data.phone})
    //     console.log("店铺信息", res.data)
    //   })
    // }else{
    //   this.headFlag = true
    //   this.footerFlag = true
    // }
    // let shopName = this.cookie.getCookie("shopName")
    this.headFlag = true
    this.footerFlag = true
    // if(shopName != null){
    //   this.headFlag = true
    //   this.footerFlag = true
    // }
    let width = document.body.clientWidth;
    if (width <= 800 && width > 400) {
      this.colCount = 2;
    } else if (width <= 400) {
      this.colCount = 1;
    }

    // 获取banner
    this.getBanners();
    // 获取链接导航模块 
    this.getBannerModual();
    // 视频教程
    this.getBannerVedio();
    //获取设计师
    this.getDesingers();
    //获取二维方案
    this.getMasterpieces();
    //获取场景购画布
    this.getCanvas();
    //获取三维方案
    // this.getDesign3D();
    //获取模型列表
    this.getModeList();
    // document.getElementById("form").on('submit', function () {
    //   alert("It works");
    // });
  },
  methods: {
    collectionMode(item) {
      const that = this;
      if (!dump.checkLogin()) {
        return false;
      }
      let followFlag = 1;
      if (item.followFlag == 1) {
        followFlag = 0;
      }
      that.$api.collection({
        collection_type: 3,
        project_id: item.modelId
      }).then(res => {
        // console.log(res);
        if (res.status == 100) {
          item.followFlag = followFlag;
        }
      })
    },
    collectionDesign3D(info) {
      const that = this;
      if (!dump.checkLogin()) {
        return false;
      }
      let collectionType = 1;
      if (info.collectionType == 1) {
        collectionType = 0;
      }
      that.$api.collection3D({
        worksId: info.worksId,
        collectionType: collectionType
      }).then(res => {
        if (res.status == 100) {
          info.collectionType = collectionType;
          if (info.collectionType == 1) {
            info.collectionNumber++;
          } else if (info.collectionType == 0 && info.collectionNumber > 0) {
            info.collectionNumber--;
          }
        }
      })
    },
    getModeList() {
      const that = this;
      that.$api.modeList({
        pageNum: 1,
        pageSize: 18
      }).then(res => {
        // console.log(res);
        res.data.list.forEach((item) => {
          let imgs = item.img.split(',');
          item.coverUri = imgs[0];
        })
        that.modeList = res.data.list;
        // console.log(res.data.list)
      })
    },
    // 处理文件上传操作
    handleFileUpload(file, fileList) {
      this.$refs.upload.clearFiles()
      let length = fileList.length;
      this.maxLength = Math.max(length, this.maxLength)
      console.log(file.raw)
      setTimeout(() => {
        if (length == this.maxLength) {
          var formData = new FormData();
          formData.append("file", file.raw)
          this.$api.ossUpload(formData).then(res => {
            console.log(res.data)
            this.searchImage = res.data
            var d = document.querySelector(".general-upload");
            var e = document.createElement("input")
            // e.href = "http://baidu.com"
            e.style = "display:none"
            e.type = "button"
            e.onclick = this.handleWindowOpen
            d.appendChild(e)
            e.click()
            // e.trigger('click')
          }).catch((e) => {
            this.$message.error(e.message);
          })
        }
      }, 100)
    },
    handleWindowOpen() {
      dump.link({
        type: 3,
        link: "/searchImg",
        params: {
          searchImage: this.searchImage
        }
      })
    },
    openUploadAdd() {
      if (this.openUpload == false) {
        this.openUpload = true
      } else {
        this.openUpload = false
      }
    },
    // closeUploadAdd(){
    //   console.log("关闭")
    //   this.openUpload = 
    // },
    search() {
      const that = this;
      if (that.selectInfo.id == 1) {
        //商品
        let data = {
          type: 3,
          link: '/search',
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      } else if (that.selectInfo.id == 2) {
        //二维
        let data = {
          type: 3,
          link: "/planeScheme",
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      } else if (that.selectInfo.id == 3) {
        //三维
        let data = {
          type: 3,
          link: "/threeDimensional",
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      } else if (that.selectInfo.id == 4) {
        //三维模型
        let data = {
          type: 3,
          link: '/tMode',
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      }
    },
    //场景购详情
    toCanvasDetail(item) {
      item.type = 3;
      item.link = "/sceneInfo";
      item.params = {
        id: item.id
      };
      dump.link(item)
    },
    to2DDetail(item) {
      item.type = 3;
      item.link = "/twodimensionalprogramme";
      item.params = {
        id: item.id
      };
      dump.link(item);
    },
    to3DDetail(item) {
      item.type = 2;
      // item.link = item.threeDUrl;
      item.link =
        `${url}/mapp/plan/getThreeDimensional?url=${url}&workId=${item.worksId}&token=${localStorage.getItem("token")}`;
      dump.link(item);
    },
    getDesign3D() {
      // const that = this;
      // that.$api.design3D({
      //   currentPage: 1,
      //   pageSize: 18
      // }).then(res => {
      //   // console.log(res);
      //   // that.designs3dList = [];
      //   that.designs3dList = res.data.list;

      // })
      this.$api.design3DAll({
        currentPage: 1,
        pageSize: 18
      }).then(res => {
        this.designs3dList = res.data.list;
      }).catch(err => console.error(err));
    },
    getCanvas() {
      this.$api.canvasSearch({
        currentPage: 1,
        pageSize: 18
      }).then(res => {
        this.canvasList = res.data.list
        console.log("canvas", this.canvasList)
      })
    },
    getMasterpieces() {
      const that = this;
      that.$api.designMasterpieces({
        pageIndex: 1,
        pageSize: 25
      }).then((res => {
        // console.log(res);
        // that.masterpiecesList = res.data.list;
        if (res.code == 200) {
          that.masterpiecesList = [];
          that.masterpiecesList = res.data.list;
          that.totalPage = res.data.pageTotals;
        }
      }))
    },
    getDesingers() {
      const that = this;
      that.$api.designers({
        pageIndex: 1,
        pageSize: 6
      }).then(res => {
        if (res.code == 200) {
          that.designers = res.data.list;
        }
        // res.data.list.forEach((item) => {
        // 	let temp = {
        // 		"headImgUrl": item.headImgUrl,
        // 		"nickName": item.nickName,
        // 		"mySignature": item.mySignature,
        // 		"followType": item.collectStatus,
        // 		designNumber: item.designNum
        // 	}
        // 	that.designers.push(temp);
        // })
      })
    },
    getMeal() {

    },
    getBanners() {
      const that = this;
      that.$api.banners({
        banner_type: 1
      }).then(res => {
        that.banners = res.data;
      })
    },
    to2DSolution() {
      const that = this;
      let data = {
        type: 3,
        link: "/planeScheme",
        params: {
          keyWords: that.keyWords
        }
      };
      dump.link(data);
    },
    dumpCanvasSolution() {
      let data = {
        type: 3,
        link: "/scenePurchase"
      };
      dump.link(data);
    },
    dump3DSolution() {
      // const that = this;
      let data = {
        type: 3,
        link: "/threeDimensional"
      };
      dump.link(data);
    },
    dump3DModeList() {
      // const that = this;
      let data = {
        type: 3,
        link: "/tMode"
      };
      dump.link(data);
    },
    followDesigner(designer) {
      if (!dump.checkLogin()) {
        return false;
      }
      // let showMsg;
      let flag;
      if (designer.followStatus == 1) {
        // showMsg = this.$t("designerFollowOut");
        flag = 2;
      } else {
        // showMsg = this.$t("designerFollowIn");
        flag = 1;
      }
      let data = {
        flag: flag,
        targetMemberId: designer.id
      };
      this.$api.followDesigner(data).then(res => {
        if (res.code == 200) {
          // this.$alert(showMsg);
          if (flag == 1) {
            designer.followStatus = 1;
          } else {
            designer.followStatus = 0;
          }
        }
      })

    },
    followSolution(solution) {
      if (!dump.checkLogin()) {
        return false;
      }
      // let showMsg;
      let flag;
      if (solution.collectStatus == 1) {
        // showMsg = this.$t("designerFollowOut");
        flag = 2;
      } else {
        // showMsg = this.$t("designerFollowIn");
        flag = 1;
      }
      let data = {
        flag: flag,
        colId: solution.id,
        colType: solution.colType || 1
      };
      this.$api.followSolution(data).then(res => {
        if (res.code == 200) {
          // this.$alert(showMsg);
          if (flag == 1) {
            solution.collectStatus = 1;
          } else {
            solution.collectStatus = 0;
          }

        }
      })

    },
    // 跳转到3D模型详情
    to3DModelDetail(item) {
      item.type = 3
      item.link = "/model3Ddetail";
      item.params = {
        id: item.modelId
      }
      dump.link(item);
    },
    //场景购选择分类
    canvasSelectLabel(obj, i) {
      const that = this;
      that.design3DActiveIdx = i;
      that.$api.canvasSearch({
        currentPage: 1,
        pageSize: 18,
        sceneSpaceId: obj.labelId
      }).then(res => {
        if (res.status == 200) {
          that.canvasList = [];
          that.canvasList = res.data.list;
        }
      })
    },
    // 二维方案选择分类
    design2DSelectLabel(obj, i) {
      const that = this;
      that.design2DActiveIdx = i;
      that.$api.designMasterpieces({
        pageIndex: 1,
        pageSize: 25,
        style: obj.labelId
      }).then((res => {
        if (res.code == 200) {
          that.masterpiecesList = [];
          that.masterpiecesList = res.data.list;
          that.totalPage = res.data.pageTotals;
        }
      }))
    },
    // 三维方案选择分类
    design3DSelectLabel(obj, i) {
      const that = this;
      that.design3DActiveIdx = i;
      if (i) {
        that.$api.design3D({
          currentPage: 1,
          pageSize: 18,
          styleId: obj.labelId
        }).then(res => {
          that.designs3dList = res.data.list;
        })
      } else {
        that.$api.design3DAll({
          currentPage: 1,
          pageSize: 18,
          styleId: obj.labelId
        }).then(res => {
          that.designs3dList = res.data.list;
        }).catch(err => console.error(err));
      }
    },
    // 3D模型选择分类
    model3DSelectLabel(obj, i) {
      const that = this;
      that.model3DActiveIdx = i;
      that.$api.modeList({
        pageNum: 1,
        pageSize: 18,
        secondLabelId: obj.classifyId
      }).then(res => {
        res.data.list.forEach((item) => {
          let imgs = item.img.split(',');
          item.coverUri = imgs[0];
        })
        that.modeList = res.data.list;
      })
    },
    // 获取首页链接导航模块 
    getBannerModual() {
      this.$api.bannerModual({}).then(res => {
        if (res.status == 100) {
          // 删除最后一项，目的是为了屏蔽VIP入口
          res.data.pop();
          // res.data.push({
          //   link: "/scenePurchase",
          //   poster: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2facc418-0e69-4328-9579-3a95c7cd6cf4.png",
          //   sourceType: "2",
          //   src: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2facc418-0e69-4328-9579-3a95c7cd6cf4.png",
          //   title: "场景购",
          //   type: "1"
          // })
          this.navLink = res.data;
        }
      }).catch(err => console.error(err))
    },
    // 获取首页视频教程
    getBannerVedio() {
      this.$api.bannerVedio({
        vedioType: 1
      }).then(res => {
        if (res.status == 100) {
          this.videoList = res.data;
        }
      }).catch(err => console.error(err))
    },
    // 跳转到更多视频
    toVideoTutorial() {
      dump.link({
        type: 3,
        link: "/videoTutorial"
      })
    }
    // changeLanguage(lang) {
    // 	// 保存语言配置到localStorage
    // 	localStorage.setItem("locale", lang);
    // 	// 修改显示语言
    // 	this.$i18n.locale = lang;
    // 	// TODO 修改远程配置
    // }
  }
}
</script>
<style scoped="scoped">
.jumbotron {}

.soutu-state-normal {
  margin: auto;
  padding: 24px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
  border-radius: 12px;
  font-size: 13px;
  color: #9195A3;
  width: 52.3rem;

  .graph-d20-search-layer-choose {
    position: relative;
    width: 78px;
    height: 30px;
    line-height: 31px;
    text-align: center;
    color: #fff;
    margin: 16px auto 24px;
    background: #4e6ef2;
    border-radius: 6px;

    /* .general-upload {
      top: 0;
      position: absolute;
    } */
  }
}

.carousel-control-prev-icon {
  background-image: url(/icon/banner-left@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.carousel-control-prev {
  background: unset;
  border: unset;
  width: unset !important;
  margin-left: 1.5rem;
}

.carousel-control-next-icon {
  background-image: url(/icon/banner-right@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;

}

.carousel-control-next {
  background: unset;
  border: unset;
  width: unset !important;
  margin-right: 1.5rem;

}

.hiddenUpload {
  display: none;
}

.search-box {
  position: absolute;
  width: 100%;
}

.search-box>.search-input-box {
  height: 4rem;
  background: #FFFFFF;
  border-radius: 0.5rem;
  margin: auto;
  z-index: 9;
  display: flex;
}

/deep/ .carousel-control-prev-icon {
  background-image: url(/icon/banner-left@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

/deep/ .carousel-control-prev {
  background: unset;
  border: unset;
  width: unset !important;
  margin-left: 1.5rem;
}

/deep/ .carousel-control-next-icon {
  background-image: url(/icon/banner-right@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

/deep/ .carousel-control-next {
  background: unset;
  border: unset;
  width: unset !important;
  margin-right: 1.5rem;
}

/deep/ .carousel-indicators li {
  background-color: #000;
  opacity: 0.2;
}

/deep/ .carousel-indicators .active {
  background-color: #d19d7e;
  opacity: 1;
}

.margin-left-auto {
  margin-left: auto !important;
}

.margin-right-22 {
  margin-right: 1.375rem !important;
}

.search-select {
  background: #ffffff !important;
  border: unset !important;
  border: unset !important;
  color: #000 !important;
  box-shadow: unset !important;
  height: 100% !important;
  border-radius: 0.5rem;
}

.key-words-box {
  margin: auto;
  padding: 1.5rem;
  font-size: 0.9375rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: left;
}

.key-words {
  padding: 0 0.9375rem;
  cursor: pointer;
}

.search-input-info {
  border: none;
  outline: medium;
}

.search-box {
  position: absolute;
  width: 100%;
}


.text-align-left {
  text-align: left;
}

.video-box {
  display: flex;
  padding: 1.375rem 0;
  max-width: 100%;
}

.video-box-con {
  width: 24%;
  margin: 0 auto;
}

.video {
  display: block;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.video-box-con:not(:first-child) {
  margin-left: 1%;
}

/* .video {
    margin: auto;
    width: 24%;
  }

  .video:not(:first-child) {
    margin-left: 1%;
  } */

@media screen and (min-width: 320px) {
  .search-split-box {
    height: 100%;
    display: flex;
    margin: 0 0.5rem;
  }

  .search-icon {
    width: 1.875rem;
    margin: auto 0;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .search-box {
    bottom: 1rem;
  }

  .search-box>.search-input-box {
    width: 37.5rem;
  }

  .key-words-box {
    width: 37.5rem;
  }

  .search-input-info {
    width: 34.375rem;
  }

  .search-split-box {
    height: 100%;
    display: flex;
    margin: 0 0.5rem;
  }

  .search-split {
    height: 60%;
    margin: auto 0;
  }

  .search-icon {
    /* height: 1.875rem; */
    width: 1.875rem;
    margin: auto 0;
    cursor: pointer;
  }

  .container-index {
    padding: 0 0.625rem;
  }

}

@media (min-width: 1200px) {
  .search-box {
    position: absolute;
    bottom: 5.625rem;
    width: 100%;
  }

  .search-box>.search-input-box {
    width: 52.5rem;
  }

  .key-words-box {
    width: 52.5rem;
  }

  .search-input-info {
    width: 34.375rem;
  }

  .container-index {
    padding: 0 6.1875rem;
  }

}
</style>
<style lang="scss" scoped="scoped">
@import "../../assets/scss/style.scss";

.container-index {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }

  .designer-box {
    max-width: 100%;



    .designer:not(:first-child) {
      border-left: solid 0.125rem rgba(200, 200, 200, 0);
    }

    .designer {
      width: 18rem;
      height: 23.75rem;
      background: #FFFFFF;
      text-align: center;
      align-items: center;
      display: flex;
      box-shadow: 0 0 0.3125rem rgba(200, 200, 200, 0.5);
      margin: 0 auto;
      cursor: pointer;

      .designer-item {
        text-align: center;
        width: 100%;

        .designer-avatar {
          width: 7.5rem;
          height: 7.5rem;
          border-radius: 50%;
        }

        .nick-name {
          padding-top: 1rem;
        }

        .design-num {
          padding-top: 0.4375rem;
        }

        .signature {
          padding-top: 0.75rem;
        }

        .follow {
          margin-top: 0.4375rem;
          border-radius: 1.25rem;
          border: unset;
          padding: 0.125rem 1.25rem;
          background: $bg-default-ns-color;
        }

        .follow-success {
          border: 0.0625rem solid $bg-default-s-color;
          color: $bg-default-s-color;
          background: unset;
        }

        .follow:active {
          background: $bg-default-s-color;
          color: #FFFFFF;
        }

      }

    }
  }

  .ehome-title-box {
    .label-box {
      display: flex;
      margin-left: auto;
      margin-right: 1.25rem;

      .active {
        border-radius: 0.625rem;
        background-color: #d19d7e;
        color: #fff;
      }

      .label {
        position: relative;
        padding: 0 0.875rem;
        height: 1.25rem;
        line-height: 1.25rem;
        margin: auto 0.25rem;
        // border-right: 0.125rem solid $bg-default-ns-color;
        cursor: pointer;

        &:after {
          position: absolute;
          top: 0;
          right: -0.25rem;
          content: "";
          width: 1px;
          height: 100%;
          background-color: $bg-default-ns-color;
          z-index: 10;
        }
      }

      .label:not(:first-child) {}
    }
  }

  .solution-box {
    display: flex;
    flex-direction: row;

    .solution {

      // background: #F7F8F8;
      // box-shadow: 0px 0px 0.9375rem 0px rgba(0, 0, 0, 0.2);
      .model-items-wrap,
      .vue-waterfall-column {
        .solution-item {
          background: #FFFFFF;
          padding: 0.625rem;
          cursor: pointer;

          .solution-item-ehome {
            box-shadow: 0px 0px 0.375rem 0px rgba(0, 0, 0, 0.2);
            background: #F7F8F8;
            position: relative;

            .mode-img {
              width: 100%;
              object-fit: cover;
            }

            .collection-button {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2.5rem;
              height: 2.5rem;
              top: 0.5rem;
              right: 0.5rem;
              z-index: 9999;
              background: #FFFFFF;
              border-radius: 0.375rem;
              opacity: 0;

              // border: 0.0625rem solid #F0F7FF;
              .collection-msg {
                opacity: 0;
                position: absolute;
                display: flex;
                top: 2.7rem;
                background: #000000;
                color: #FFFFFF;
                border-radius: 0.25rem;
                padding: 0.125rem 0.25rem;
                font-size: 0.5rem;
              }

              &:hover {
                .collection-msg {
                  opacity: 1;
                }
              }
            }

            &:hover {
              .collection-button {
                opacity: 1;
              }
            }


            .solution-img {
              max-width: 100%;
              height: auto;
              z-index: 9998;
            }

            .solution-name {
              padding-top: 0.875rem;
              padding-left: 0.6875rem;
              text-align: left;
              width: 100%;
            }

            .model-name {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .solution-user-box {
              padding: 0.875rem 0.6875rem 0.4375rem 0.6875rem;
              display: flex;
              align-items: center;

              .solution-user-avatar {
                width: 1.8125rem;
                height: 1.8125rem;
                border-radius: 50%;
              }

              .solution-designer-name {
                padding-left: 0.3125rem;
                max-width: 80%;
                overflow: hidden;
                margin: auto 0;
              }

              .solution-designer-name-mode {
                padding-left: 0.3125rem;
                max-width: 50%;
                overflow: hidden;
                margin: auto 0;
              }

              .watch-icon {
                margin-left: auto;
              }

              .icon-padding {
                padding-left: 0.375rem;
              }

              .solution-watch-number {
                padding-left: 0.375rem;
                margin: auto 0;
              }

            }
          }
        }
      }

      .b-row {
        margin-right: 0;

        .b-col {
          padding-right: 0;
          margin-bottom: 15px;

          .solution-item {
            width: 100%;
            height: 100%;
            padding: 0;

            .solution-item-ehome {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;

              .pic {
                flex-grow: 1;

                img {
                  min-width: 100%;
                  min-height: 100%;
                }
              }

              .solution-name,
              .ehome-solution-name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

  }


}

@media screen and (max-width:767px) {
  .container-index {
    .ehome-title-box {
      .label-box {
        display: none;
      }
    }
  }


}
</style>
